import { ServicesDisplayed } from "../utils/mockData";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { Heading, Select, useMediaQuery } from "@chakra-ui/react";
import {
  MainWrapper,
  HeadingWrapper,
  CustomSideStack,
  ContentWrapper,
  TitleAndText,
  BgImage,
  StyledText,
  StyledHeading,
  ViewMoreText,
  ServicesComponentWrapper,
  ServicesWrapper,
} from "../components/services/ServicesComponent.styled";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import { useContext, useEffect } from "react";
import { StyledAiOutlineArrowLeft } from "../common/styles/common.styled";
import { ThemeContext } from "../state/ThemeContext";

export const Services = () => {
  const [t, i18] = useTranslation();
  const navigate = useNavigate();

  document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
    anchor.addEventListener("click", function (e) {
      e.preventDefault();

      document.querySelector(this.getAttribute("href")).scrollIntoView({
        behavior: "smooth",
      });
    });
  });

  useEffect(() => {
    document.title = t("Kreativa");
  }, [i18.resolvedLanguage, t]);

  const { theme } = useContext(ThemeContext);

  const [isSmallerThan600] = useMediaQuery("(max-width: 600px)");
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    const element = document.getElementById(selectedValue);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <ServicesWrapper className="GlobalPageBox">
      <CustomSideStack className="SecondSideMenu">
        <a href="#metal-ceramics">{t("Metal Ceramics")}</a>
        <a href="#metal-free-ceramics">{t("Metal-free Ceramics")}</a>
        <a href="#removable-dentures">{t("Removable Dentures")}</a>
        <a href="#retainers">{t("Retainers")}</a>
        <a href="#wax-up">{t("Wax Up")}</a>
        <a href="#digital-smile-design">{t("Digital Smile Design")}</a>
        {/* <a href="#new-route">{t("New Section")}</a> */}
        <a href="#color-matching">{t("Color Matching")}</a>
        <a href="#consultations">{t("Consultations")}</a>
        <a href="#dental-photography">{t("Dental Photography")}</a>
      </CustomSideStack>
      <MainWrapper className="MainWrapper">
        <HeadingWrapper>
          <StyledHeading>
            <StyledAiOutlineArrowLeft onClick={() => navigate(-1)} />
            {t("Services")}
          </StyledHeading>
          {isSmallerThan600 && (
            <Select
              style={{ marginBottom: "10px" }}
              placeholder={t("Choose a service")}
              variant="flushed"
              onChange={handleSelectChange}
            >
              <option value="metal-ceramics">{t("Metal Ceramics")}</option>
              <option value="metal-free-ceramics">
                {t("Metal-free Ceramics")}
              </option>
              <option value="removable-dentures">
                {t("Removable Dentures")}
              </option>
              <option value="retainers">{t("Retainers")}</option>
              <option value="wax-up">{t("Wax Up")}</option>
              <option value="digital-smile-design">
                {t("Digital Smile Design")}
              </option>
              <option value="color-matching">{t("Color Matching")}</option>
              <option value="consultations">{t("Consultations")}</option>
              <option value="dental-photography">
                {t("Dental Photography")}
              </option>
            </Select>
          )}
        </HeadingWrapper>
        {/* <StyledText className="ItalicIntroText">
          {t(
            "We have the most advanced technical equipment, and we offer the highest quality restorations, with special attention paid to the end result..."
          )}
        </StyledText> */}
        <ContentWrapper>
          <StyledText className="ItalicIntroText">
            {t(
              "We have the most advanced technical equipment, and we offer the highest quality restorations, with special attention paid to the end result..."
            )}
          </StyledText>
          {ServicesDisplayed.map((imgObject) => {
            let key = "dark";

            if (!imgObject[theme]) {
              if (theme === "dark") {
                key = "light";
              } else {
                key = "dark";
              }
            } else {
              key = theme;
            }

            const { id, title, imgSrc, introText } = imgObject[key];

            return (
              <ServicesComponentWrapper key={id} id={id}>
                <BgImage imgSrc={imgSrc} />
                <TitleAndText className="Title and Text">
                  <Heading
                    as="h1"
                    fontSize="1.4375em"
                    fontWeight="300"
                    textTransform="uppercase"
                    lineHeight="1.2em"
                    // color="#2e2e2e"
                    color="#282828"
                  >
                    {t(title)}
                  </Heading>
                  <StyledText>{parse(t(introText))}</StyledText>
                  <ViewMoreText to={id}>
                    {t("More information on")} {t(title)}
                    <AiOutlineArrowRight style={{ marginLeft: "5px" }} />
                  </ViewMoreText>
                </TitleAndText>
              </ServicesComponentWrapper>
            );
          })}
        </ContentWrapper>
      </MainWrapper>
    </ServicesWrapper>
  );
};
