import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { ContactButton } from "../../Sidebar.styled";

export const Menu = ({ onClose }) => {
  const { t } = useTranslation();
  let location = useLocation();

  const isHomePage = useCallback(() => {
    const currPathname = location.pathname;
    const newPathname = currPathname.slice(1, currPathname.length);
    return newPathname === "" ? true : false;
  }, [location]);

  if (isHomePage()) {
    //OFFSET:
    // document.querySelectorAll(".scroll-link").forEach((a) => {
    //   a.addEventListener("click", (e) => {
    //     e.preventDefault();
    //     let offset; // specify offset value here
    //     if (window.innerWidth < 600) {
    //       offset = 60;
    //     } else if (window.innerWidth < 1000) {
    //       offset = 80;
    //     } else {
    //       offset = 0;
    //     }
    //     window.scrollBy({
    //       top:
    //         document
    //           .querySelector(a.getAttribute("href"))
    //           .getBoundingClientRect().top - offset,
    //       behavior: "smooth",
    //     });
    //   });
    // });
    //-------------------------------------------------------------------------
  }

  //HIGHLIGHT
  //Adding the highlight on the menu when any menu item is clicked..
  const links = document.querySelectorAll(".link");
  links.forEach((link) => {
    link.addEventListener("click", function () {
      links.forEach((link) => link.classList.remove("highlight"));
      this.classList.add("highlight");
    });
  });

  if (isHomePage()) {
    return (
      <>
        <a href="#home" className="link" onClick={onClose}>
          {t("Home")}
        </a>
        <a href="#services" className="link" onClick={onClose}>
          {t("Services")}
        </a>
        <a href="#cases" className="link" onClick={onClose}>
          {t("Cases")}
        </a>
        <a href="#about-us" className="link" onClick={onClose}>
          {t("About us")}
        </a>
        <RouterLink onClick={onClose} to="contact">
          <ContactButton>{t("CONTACT")}</ContactButton>
        </RouterLink>
      </>
    );
  }
  return (
    <>
      <a href="/">{t("Home")}</a>
      <RouterLink className="link" onClick={onClose} to="services">
        {t("Services")}
      </RouterLink>
      <RouterLink className="link" onClick={onClose} to="view-cases">
        {t("Cases")}
      </RouterLink>
      <RouterLink className="link" onClick={onClose} to="about-us">
        {t("About us")}
      </RouterLink>
      <RouterLink onClick={onClose} to="contact">
        <ContactButton>{t("CONTACT")}</ContactButton>
      </RouterLink>
    </>
  );
};
