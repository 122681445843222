import { ContactBgData, FeelFreeIntro } from "../utils/mockData";
import { useNavigate } from "react-router-dom";
import {
  MainWrapper,
  BgWrapper,
  ContentWrapper,
  TitleToMapWrapper,
  StyledHeading,
  WaysToContact,
  FeelFree,
  CustomAspectRatio,
} from "../components/contact/ContactComponent.styled";
import { Box, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useMemo } from "react";
import {
  StyledAiOutlineArrowLeft,
  StyledAiOutlineDesktop,
  StyledAiOutlineMail,
  StyledBiPhone,
  StyledVscLocation,
} from "../common/styles/common.styled";
import { ThemeContext } from "../state/ThemeContext";

export const ContactPage = () => {
  const [t, i18] = useTranslation();

  useEffect(() => {
    document.title = t("Kreativa");
  }, [i18.resolvedLanguage, t]);

  const navigate = useNavigate();

  const { theme } = useContext(ThemeContext);

  const darkThemeValue = useMemo(() => {
    if (theme === "dark") {
      return "dark-mode";
    }
    return "";
  }, [theme]);

  const darkThemeValue2 = useMemo(() => {
    if (theme === "dark") {
      return "1px solid black";
    }
    return "1px solid gray";
  }, [theme]);

  return (
    <MainWrapper className="MainWrapper">
      <BgWrapper
        imgSrc={ContactBgData.imgSrc}
        opacity={ContactBgData.opacity}
        className="BgWrapper"
      />
      <StyledHeading>
        <StyledAiOutlineArrowLeft onClick={() => navigate(-1)} />
        {t("CONTACT US")}
      </StyledHeading>
      <ContentWrapper className="ContentWrapper">
        <TitleToMapWrapper className="TitleToMapWrapper">
          {<FeelFree>{t(FeelFreeIntro.text)}</FeelFree>}
          <WaysToContact>
            <VStack>
              <Box display="flex" alignItems="center">
                <StyledBiPhone />
                +389 (0) 2 61 52 777
              </Box>
              <Box display="flex" alignItems="center">
                <StyledAiOutlineDesktop />
                office@kreativa.com.mk
              </Box>
            </VStack>
            <VStack>
              <Box display="flex" alignItems="center">
                <StyledAiOutlineMail />
                www.kreativa.com.mk
              </Box>
              <Box display="flex" alignItems="center">
                <StyledVscLocation />
                {t("blvd. Partiznaski Odredi 33, 1000 Skopje")}
              </Box>
            </VStack>
          </WaysToContact>
          <CustomAspectRatio ratio={16 / 9} className={darkThemeValue}>
            <iframe
              style={{ borderRadius: "10px", border: { darkThemeValue2 } }}
              title="Location"
              id="gmap_canvas"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d311.6652209410118!2d21.420240394724104!3d41.9986447664651!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1354144eb50024a3%3A0x2884f48697e3854b!2sKreativa!5e0!3m2!1sen!2smk!4v1717888940067!5m2!1sen!2smk"
            ></iframe>
          </CustomAspectRatio>
        </TitleToMapWrapper>
      </ContentWrapper>
    </MainWrapper>
  );
};
