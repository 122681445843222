import { useBreakpointValue } from "@chakra-ui/react";
import React, { useState } from "react";
import Sidebar from "../components/common/Sidebar/Sidebar";
import Header from "../components/common/Header/Header";
import {
  AppWrapper,
  ChildChildWrapper,
  ChildWrapper,
} from "./AppLayout.styled";

const AppLayout = ({ children }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const smVariant = { navigation: "drawer", navigationButton: true };
  const lgVariant = { navigation: "sidebar", navigationButton: false };
  const variants = useBreakpointValue({ base: smVariant, lg: lgVariant });
  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);

  return (
    <AppWrapper className="AppLayout-AppWrapper">
      <Sidebar
        variant={variants?.navigation}
        isOpen={isSidebarOpen}
        onClose={toggleSidebar}
      />
      <ChildWrapper>
        <Header
          showSidebarButton={variants?.navigationButton}
          onShowSidebar={toggleSidebar}
        />
        <ChildChildWrapper className="AppLayout-ChildChildWrapper">
          {children}
        </ChildChildWrapper>
      </ChildWrapper>
    </AppWrapper>
  );
};

export default AppLayout;
