import styled from "styled-components";

export const AppWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  /* resize: "horizontal"; */
  /*this should work????????? */ /*Another way of dealing with the landscape issue (needs to be tested after it's deployed) */
`;

export const ChildWrapper = styled.div`
  width: 100%;
`;

export const ChildChildWrapper = styled.div`
  height: 100vh;
  margin-left: 320px;
  /* @media (max-width: 1400px) {
    margin-left: 300px;
  } */
  @media (max-width: 1300px) {
    margin-left: 270px;
  }
  @media (max-width: 1000px) {
    height: calc(100vh - 84px);
    margin-left: 0px;
    margin-top: 84px;
  }
  @media (max-width: 600px) {
    height: calc(100vh - 78px);
    margin-top: 78px;
  }
  /* position: fixed; */
  /* overflow: hidden; */
`;
