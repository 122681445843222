import { DrawerCloseButton } from "@chakra-ui/react";
import { Drawer, DrawerOverlay, DrawerBody } from "@chakra-ui/react";
import { useContext, useMemo } from "react";
import styled from "styled-components";
import { ThemeContext } from "../../../state/ThemeContext";
import { SidebarContent } from "./components/SidebarContent/SidebarContent";
import { CustomDrawerContent, MenuBox } from "./Sidebar.styled";

const StyledDrawer = styled(Drawer)({
  background: "rgb(24, 26, 27) !important",
  fontSize: "10rem",
});

const Sidebar = ({ isOpen, variant, onClose }) => {
  const { theme } = useContext(ThemeContext);

  const darkThemeValue = useMemo(() => {
    if (theme === "dark") {
      return "#181a1b";
    }
    return "#fff";
  }, [theme]);

  return variant === "sidebar" ? (
    <MenuBox className="MenuBox">
      <SidebarContent showLogo={true} />
    </MenuBox>
  ) : (
    <StyledDrawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      className="StyledDrawer"
    >
      <DrawerOverlay className="Sidebar-DrawerOverlay">
        <CustomDrawerContent
          bgColorValue={darkThemeValue}
          className="Sidebar-DrawerContent"
          style={{ top: "auto" }}
        >
          <DrawerCloseButton pt="10px" />
          <DrawerBody className="Sidebar-DrawerBody">
            <SidebarContent onClose={onClose} showLogo={false} />
          </DrawerBody>
        </CustomDrawerContent>
      </DrawerOverlay>
    </StyledDrawer>
  );
};

export default Sidebar;
