import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import { AboutUsData } from "../utils/mockData";
import {
  MainWrapper,
  ContentWrapper,
  StyledText,
  StyledHeading,
  BgWrapper,
  // EmployeesWrapper,
  // PlaceholderImage,
} from "../components/aboutus/AboutUsComponent.styled";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { StyledAiOutlineArrowLeft } from "../common/styles/common.styled";
// import ApplyLight from "../images/ApplyNow/ApplyLight.png";
// import ApplyDark from "../images/ApplyNow/ApplyDark.png";
import { ApplyNowButton } from "../components/aboutus/ApplyNowButton";

export const AboutUsPage = () => {
  const [t, i18] = useTranslation();

  useEffect(() => {
    document.title = t("Kreativa");
  }, [i18.resolvedLanguage, t]);

  const navigate = useNavigate();

  return (
    <MainWrapper className="MainWrapper">
      <BgWrapper
        className="BgWrapper"
        imgSrc={AboutUsData.imgSrc}
        opacity={AboutUsData.opacity}
      />
      <StyledHeading>
        <StyledAiOutlineArrowLeft onClick={() => navigate(-1)} />
        {t("TK_TTL_ABOUT_KREATIVA")}
        {/** TK_TTL_ABOUT_KREATIVA - BEST PRACTICE (SMENETO E VO JSON-FILE-OVITE) - TK(TRANSLATION KEY) / TTL(TITLE) */}
      </StyledHeading>
      <ContentWrapper className="ContentWrapper">
        <StyledText>{parse(t(AboutUsData.text))}</StyledText>
        {/* <EmployeesWrapper href="/apply-form" className="EmployeesWrapper"> */}
        {/* <PlaceholderImage
            className="PlaceholderImage"
            src={darkThemeValue}
            alt="Join our team Card"
            boxSize="200px"
          /> */}
        <ApplyNowButton className="ApplyButton" whereTo="/apply-form" />
        {/* </EmployeesWrapper> */}

        {/* <EmployeesWrapper className="EmployeesWrapper">
          {EmployeesCard.map(({ btnData }) => (
            <>
              <PlaceholderImage
                className="PlaceholderImage"
                imgSrc={darkThemeValue}
              />
              {btnData}
            </>
          ))}
        </EmployeesWrapper> */}
      </ContentWrapper>
    </MainWrapper>
  );
};
