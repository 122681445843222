import React, { StrictMode, Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./components/common/theme";
import "./App.css";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { LoadingIndicator } from "./common/components/LoadingIndicator";
import { ThemeContextProvider } from "./state/ThemeContext";
// import { enable as enableDarkMode } from "darkreader";

// enableDarkMode({
//   brightness: 100,
//   contrast: 100,
//   sepia: 0,
//   grayscale: 0,
// });

//Ako ne saka da ima kopce, i da bide samo dark, togas so ovoj kod tuka;

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    supportedLngs: ["en", "mk", "al"],
    fallbackLng: "en",
    detection: {
      order: ["cookie", "htmlTag", "localStorage", "path", "subdomain"],
      caches: ["cookie"],
      // saveDetection: true,
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
  });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense fallback={<LoadingIndicator />}>
    <StrictMode>
      <ThemeContextProvider>
        <ChakraProvider theme={theme}>
          <App />
        </ChakraProvider>
      </ThemeContextProvider>
    </StrictMode>
  </Suspense>
);
