import {
  AllDigitalSmileDesignPage,
  DigitalSmileDesignData,
  DigitalSmileDesignPage,
} from "../utils/mockData";
import { useNavigate } from "react-router-dom";
import { Box, Heading, Image } from "@chakra-ui/react";
import parse from "html-react-parser";
import {
  MainWrapper,
  ImagesWrapper,
  HeadingWrapper,
  ContentWrapper,
  StyledText,
  StyledHeading,
  TitleText,
} from "../components/services/DigitalSmileDesign/DigitalSmileDesign.styled";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { StyledAiOutlineArrowLeft } from "../common/styles/common.styled";
import ImageViewer from "react-simple-image-viewer";
import { ThemeContext } from "../state/ThemeContext";

export const DigitalSmileDesign = () => {
  const [t, i18] = useTranslation();
  const navigate = useNavigate();

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = (index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const getTemplateColumns = (num) => {
    switch (num) {
      case 1:
        return "1fr";
      case 2:
        return "1fr 1fr";
      case 3:
        return "1fr 1fr 1fr";
      default:
        return "1fr";
    }
  };

  useEffect(() => {
    document.title = t("Kreativa");
  }, [i18.resolvedLanguage, t]);

  const { theme } = useContext(ThemeContext);

  // const openImage = (idx, broj) => {
  //   broj !== 0 && broj !== 1 && openImageViewer(idx + broj);
  // };

  return (
    <Box backgroundColor="#f7f7f7" height="100%" className="DSD-Box">
      {isViewerOpen && (
        <ImageViewer
          src={AllDigitalSmileDesignPage}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}

      <MainWrapper className="MainWrapper">
        <HeadingWrapper>
          <StyledHeading>
            <StyledAiOutlineArrowLeft onClick={() => navigate(-1)} />
            {t(DigitalSmileDesignData.title)}
          </StyledHeading>
        </HeadingWrapper>

        <ContentWrapper>
          {DigitalSmileDesignPage.imagesObjArr.map((imgObject, idx) => {
            let key = "dark";

            if (!imgObject[theme]) {
              if (theme === "dark") {
                key = "light";
              } else {
                key = "dark";
              }
            } else {
              key = theme;
            }

            const { title, text, broj, columnNum, imgs } = imgObject[key];

            return (
              <>
                <TitleText>
                  {title && (
                    <Heading
                      as="h1"
                      fontSize="1.4375em"
                      fontWeight="300"
                      textTransform="uppercase"
                      lineHeight="1.2em"
                      // color="#4d4e4e"
                      color="#282828"
                    >
                      {parse(t(title))}
                    </Heading>
                  )}
                </TitleText>
                {text && <StyledText>{parse(t(text))}</StyledText>}
                {/* <ImagesWrapper
                  key={broj + idx}
                  templateColumns={getTemplateColumns(columnNum)}
                >
                  {
                    <>
                      {imgs.map(({ img, text }, idx) => (
                        <div
                          key={idx}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                          }}
                        >
                          {img && (
                            <Image
                              src={img}
                              onClick={() => openImageViewer(idx + broj)}
                              style={{
                                cursor: "pointer",
                              }}
                            />
                          )}
                          {text && <StyledText>{parse(t(text))}</StyledText>}
                        </div>
                      ))}
                    </>
                  }
                </ImagesWrapper> */}
              </>
            );
          })}
        </ContentWrapper>
      </MainWrapper>
    </Box>
  );
};
