export const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
    flag: false,
  },
  {
    code: "mk",
    name: "Macedonian",
    country_code: "mk",
    flag: false,
  },
  {
    code: "al",
    name: "Albanian",
    country_code: "al",
    flag: false,
  },
  {
    code: "tr",
    name: "Turkish",
    country_code: "tr",
    flag: true,
  },

  {
    code: "gr",
    name: "Greek",
    country_code: "gr",
    flag: true,
  },
];
