import { Button } from "@chakra-ui/button";
import { Formik, Form } from "formik";
import styled from "styled-components";
import * as Yup from "yup";
import { MyInput } from "./common/MyInput";
import { MyTextarea } from "./common/MyTextArea";
import { IoMdContact } from "react-icons/io";
import { FaPaperPlane } from "react-icons/fa";
import { MdPhoneAndroid } from "react-icons/md";
import { MyCheckbox } from "./common/MyCheckbox";
import { Text } from "@chakra-ui/layout";
import emailjs from "@emailjs/browser";
import { useToast } from "@chakra-ui/toast";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const MyInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  > * + * {
    margin-top: 1rem;
  }
`;

export const ApplyNowForm = () => {
  const [t, i18] = useTranslation();
  const toast = useToast();

  return (
    <>
      <Formik
        initialValues={{
          firstAndLastName: "",
          email: "",
          number: "",
          message: "",
          acceptedTerms: false, // added for our checkbox
        }}
        validationSchema={Yup.object({
          firstAndLastName: Yup.string()
            .max(30, "Must be 30 characters or less")
            .required("Required"),
          email: Yup.string()
            .email("Invalid email address")
            .required("Required"),
          number: Yup.string().required("Required"),
          message: Yup.string()
            .min(10, "Must be 10 or more characters")
            .max(200, "Maximum number of 200 characters")
            .required("Required"),
          acceptedTerms: Yup.boolean()
            .required("Required")
            .oneOf([true], "You must accept the terms and conditions."),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          await new Promise((r) => setTimeout(r, 2000));
          setSubmitting(false);

          emailjs
            .send(
              "service_bpikwjf",
              "template_kyfaugo",
              values,
              "ojyNgmG7UwJZxvWr7"
            )
            .then(
              function (response) {
                // console.log("SUCCESS!", response.status, response.text);
                if (response) {
                  toast({
                    title: `Application submitted!`,
                    description:
                      "Thanks for submitting your application. Our team will get back to you soon.",
                    status: "success",
                    position: "bottom",
                    duration: 8000,
                    isClosable: true,
                  });
                }
              },
              function (error) {
                // console.log("FAILED...", error);
                if (error) {
                  toast({
                    title: `Error submitting application`,
                    description: "There was an error processing your request",
                    status: "error",
                    position: "bottom",
                    duration: 8000,
                    isClosable: true,
                  });
                }
              }
            );
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <MyInputsWrapper>
              <MyInput
                label={t("Name and Surname")}
                name="firstAndLastName"
                type="text"
                children={
                  <IoMdContact style={{ width: "30px", height: "30px" }} />
                }
              />
              <MyInput
                label={t("Email Address")}
                name="email"
                type="email"
                children={
                  <FaPaperPlane style={{ width: "25px", height: "25px" }} />
                }
              />
              <MyInput
                label={t("Contact Number")}
                name="number"
                type="number"
                // children="+389"
                children={
                  <MdPhoneAndroid style={{ width: "30px", height: "30px" }} />
                }
              />
              <MyTextarea
                label={t("Message")}
                name="message"
                type="text"
                size="md"
                resize="none"
              />
              <Text fontSize="0.75em">
                {t(
                  "This form collects your personal data, so our support team can communicate with you and provide support. Check our "
                )}
                <Link
                  to="/privacy-policy"
                  style={{ textDecoration: "underline", fontWeight: "500" }}
                >
                  {t("Privacy policy")}
                </Link>
                {t(
                  " so you can find out how we protect and manage your sent personal data."
                )}
              </Text>
              <MyCheckbox value="Checkbox" name="acceptedTerms" type="checkbox">
                {t(
                  "I accept that Kreativa collects my data through this form."
                )}
              </MyCheckbox>
              <Button
                colorScheme="blackAlpha"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? t("Submitting...") : t("Submit")}
              </Button>
            </MyInputsWrapper>
          </Form>
        )}
      </Formik>
    </>
  );
};
