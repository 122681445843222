import { Box } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const MainWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  /* padding-left: 1rem; */
  padding-top: 4rem;
  padding-right: 2rem;
  overflow: hidden;

  /* @media (orientation: landscape) {
    body {
      display: none;
    }
  } */
  /*one way of dealing with the landscape issue (needs to be tested after it's deployed) */

  @media (max-width: 1300px) {
    /* background-color: lightblue; */
  }
  @media (max-width: 1000px) {
    /* background-color: violet; */
    padding-top: 2rem;
    padding-right: 1rem;
  }
  @media (max-width: 600px) {
    /* background-color: #ffa; */
    padding-top: 1rem;
    padding-left: 1rem;
  }
`; //className="MainWrapper"

export const ServicesWrapper = styled.div`
  background-color: #f7f7f7;
  height: 100%;
  display: flex;
  @media (max-width: 600px) {
    /* flex-direction: column; */
  }
`;

export const HeadingWrapper = styled.div`
  /* position: absolute; */
  width: 100%;
  top: 20px;
  left: 0;
  overflow: hidden;
`;

export const CustomSideStack = styled.div`
  height: 100%;
  width: 300px;
  /* width: 250px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 1rem;
  /* gap: 20px; */
  > * + * {
    margin-top: 30px;
  }
  text-transform: uppercase;
  font-size: 0.875em;
  line-height: 1.3em;
  font-weight: 400;
  /* color: #9cde47; */
  color: #000;
  /* background-color: lightblue; */
  @media (max-width: 600px) {
    display: none;
  }
`; //className="SecondSideMenu"
//------------------------------------------------------------------------------------------
export const StyledText = styled(Box)`
  font-size: 0.875em;
  font-weight: 300;
  line-height: 1.7em;
  /* color: #7a7d7d; */
  color: #3d3d3d;
  display: flex;
  justify-content: center;
  flex-direction: column;
  &.ItalicIntroText {
    font-family: "Cormorant Garamond", serif;
    font-style: italic;
    font-weight: 500;
    font-size: 1.25em;
    line-height: 1.5em;
    margin-bottom: 15px;
    @media (max-width: 600px) {
      font-size: 1em;
      line-height: 1.6em;
    }
  }
  p {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  @media (max-width: 600px) {
    /* font-size: 1.14285714em; */
    p {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
`;

export const ViewMoreText = styled(Link)`
  color: #9cde47;
  text-transform: uppercase;
  font-size: 0.875em;
  font-weight: 400;
  line-height: 1.7em;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 600px) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
`;

export const StyledHeading = styled.h1`
  font-size: 1.875em;
  font-weight: 300;
  line-height: 1.2em;
  text-transform: uppercase;
  /* color: #4d4e4e; */
  /* color: #2e2e2e; */
  color: #171717;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    margin-bottom: 10px;
    margin-top: 10px;
    /* font-size: 1.875em; */
    font-size: 1.6875em;
  }
`;
//------------------------------------------------------------------------------------------

export const ServicesComponentWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  /* gap: 20px; */
  > * + * {
    margin-left: 15px;
  }
  @media (max-width: 1300px) {
    flex-direction: column;
    > * + * {
      margin-top: 20px;
      margin-left: 0px;
    }
  }
  div:last-child() {
    margin-bottom: 500px;
  }
`; //className="ServicesComponentsWrapper"
//wrapperot na img, (title i text) passed so props;

export const BgImage = styled.div`
  width: 50%;
  height: 400px;
  background: ${(props) => `url(${props.imgSrc})`};
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 1950px) {
    width: 60%;
    height: 540px;
  }
  @media (max-width: 1900px) {
    width: 60%;
    height: 520px;
  }
  @media (max-width: 1850px) {
    width: 60%;
    height: 500px;
  }
  @media (max-width: 1800px) {
    width: 60%;
    height: 480px;
  }
  @media (max-width: 1750px) {
    width: 60%;
    height: 460px;
  }
  @media (max-width: 1700px) {
    height: 440px;
  }
  @media (max-width: 1650px) {
    height: 420px;
  }
  @media (max-width: 1600px) {
    height: 400px;
  }
  @media (max-width: 1550px) {
    height: 380px;
  }
  @media (max-width: 1500px) {
    height: 360px;
  }
  @media (max-width: 1450px) {
    height: 340px;
  }
  @media (max-width: 1400px) {
    height: 320px;
  }
  @media (max-width: 1350px) {
    height: 300px;
  }
  @media (max-width: 1300px) {
    width: 100%;
    height: 460px;
  }
  @media (max-width: 1250px) {
    height: 440px;
  }
  @media (max-width: 1200px) {
    height: 420px;
  }
  @media (max-width: 1150px) {
    height: 400px;
  }
  @media (max-width: 1100px) {
    height: 380px;
  }
  @media (max-width: 1050px) {
    height: 360px;
  }
  @media (max-width: 1000px) {
    height: 440px;
  }
  @media (max-width: 950px) {
    height: 420px;
  }
  @media (max-width: 900px) {
    height: 400px;
  }
  @media (max-width: 850px) {
    height: 380px;
  }
  @media (max-width: 800px) {
    height: 360px;
  }
  @media (max-width: 750px) {
    height: 340px;
  }
  @media (max-width: 700px) {
    height: 320px;
  }
  @media (max-width: 650px) {
    height: 280px;
  }
  @media (max-width: 600px) {
    height: 340px;
  }
  @media (max-width: 550px) {
    height: 320px;
  }
  @media (max-width: 500px) {
    height: 280px;
  }
  @media (max-width: 450px) {
    height: 260px;
  }
  @media (max-width: 400px) {
    height: 240px;
  }
  @media (max-width: 350px) {
    height: 200px;
  }
  @media (max-width: 300px) {
    height: 160px;
  }
  @media (max-width: 250px) {
    height: 140px;
  }
`; //slikata

export const TitleAndText = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  @media (max-width: 1300px) {
    width: 100%;
  }
`; //title i textot

export const ContentWrapper = styled.div`
  height: calc(100vh - 180px);
  overflow-y: auto;
  @media (max-width: 1000px) {
    height: calc(100vh - 220px);
  }
  @media (max-width: 600px) {
    height: calc(100vh - 200px);
  }
`;
