import { useEffect, useState } from "react";
import { IconButton, useMediaQuery } from "@chakra-ui/react";
import { AiOutlineArrowDown } from "react-icons/ai";

export const DownButton = () => {
  const [isSmallerThan600] = useMediaQuery("(max-width: 600px)");
  const [isSmallerThan1000] = useMediaQuery("(max-width: 1000px)");
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    setOffset(() => {
      let o = 0;
      if (isSmallerThan1000) {
        o = -80;
      }
      if (isSmallerThan600) {
        o = -60;
      }
      return o;
    });
  }, [isSmallerThan600, isSmallerThan1000]);

  return (
    <a href="#services" offset={offset} className="DownButton-LinkScroll">
      <IconButton
        display="flex"
        aria-label="Down arrow"
        variant="unstyled"
        icon={
          <AiOutlineArrowDown
            style={{
              height: "50px",
              width: "50px",
              color: "white",
              borderRadius: "50%",
              backgroundColor: "#9cde47",
            }}
          />
        }
      />
    </a>
  );
};
