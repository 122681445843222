import { Box } from "@chakra-ui/react";
// import styled from "styled-components";
import styled from "@emotion/styled";

export const MainWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  /* height tuka bese 100vh^ */
  padding: 4rem;
  @media (max-width: 1300px) {
    /* background-color: lightblue; */
  }
  @media (max-width: 1000px) {
    padding: 2rem;
    /* background-color: lightpink; */
  }
  @media (max-width: 600px) {
    padding: 1.5rem;
    /* background-color: #ffa; */
  }
`; //className="MainWrapper"

export const HeadingWrapper = styled.div`
  width: 100%;
`;
//------------------------------------------------------------------------------------------
export const StyledText = styled(Box)`
  width: 100%;
  font-size: 0.875em;
  font-weight: 300;
  line-height: 1.7em;
  /* color: #7a7d7d; */
  color: #3d3d3d;
  display: flex;
  justify-content: center;
  flex-direction: column;
  p {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  @media (max-width: 600px) {
    p {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
`;

export const StyledHeading = styled.h1`
  font-size: 1.4375em;
  font-weight: 400;
  line-height: 1.2em;
  text-transform: uppercase;
  /* color: #4d4e4e; */
  color: #171717;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;
//------------------------------------------------------------------------------------------
export const TitleText = styled.div`
  display: flex;
  flex-direction: column;
  p {
    margin-top: 20px;
    /* margin-bottom: 20px; */
  }
`;

export const ImagesWrapper = styled("div")((props) => ({
  display: "grid",
  gridTemplateColumns: props.templateColumns,
  alignItems: "start",
  justifyContent: "start",
  gridGap: "2rem",
  marginTop: "20px",
  "@media (max-width: 1200px)": {
    gridTemplateColumns: "1fr",
  },
  "@media (max-width: 800px)": {
    gridTemplateColumns: "1fr",
  },
}));

export const ContentWrapper = styled.div`
  height: calc(100vh - 200px);
  overflow-y: auto;
  /* background-color: lightgreen; */
`;
