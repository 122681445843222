import React, { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import {
  HeadingWrapper,
  MainWrapper,
  StyledHeading,
} from "../components/applyForm/ApplyForm.styled";
import { StyledAiOutlineArrowLeft } from "../common/styles/common.styled";
import { ApplyFormData } from "../utils/mockData";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ApplyNowForm } from "../components/applyForm/ApplyNowForm";

export const ApplyFormPage = () => {
  const [t, i18] = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = t("Kreativa");
  }, [i18.resolvedLanguage, t]);

  return (
    <Box backgroundColor="#f7f7f7" height="100%" className="ApplyFormPage-Box">
      <MainWrapper>
        <HeadingWrapper>
          <StyledHeading>
            <StyledAiOutlineArrowLeft onClick={() => navigate(-1)} />
            {t(ApplyFormData.title)}
          </StyledHeading>
        </HeadingWrapper>
        <ApplyNowForm className="ApplyNowForm" />
      </MainWrapper>
    </Box>
  );
};
