import React, { useState, useEffect } from "react";

const ImageSlider = ({ images, timeForFirstImage, timeForRestImages }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(
      () => {
        setCurrentImageIndex((currentImageIndex + 1) % images.length);
      },
      currentImageIndex === 0 ? 2000 : 2000
    );
    return () => clearInterval(intervalId);
  }, [currentImageIndex, images, timeForFirstImage, timeForRestImages]);

  return (
    <div className="image-slider">
      <img
        src={images[currentImageIndex]}
        alt="slider"
        className="slider-image"
      />
    </div>
  );
};

export default ImageSlider;
