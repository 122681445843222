import styled from "styled-components";

export const DynamicButton = styled.button`
  background-color: #9cde47;
  color: white;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 5px;
  line-height: 1.2;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 16px;
  padding-right: 16px;
`;
