import { Select } from "@chakra-ui/react";
import i18next from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CiGlobe } from "react-icons/ci";
import { languages } from "../../utils";

export const SelectLanguage = () => {
  const [t, i18] = useTranslation();
  const [value, setValue] = useState(i18.resolvedLanguage);
  const handleChange = (event) => {
    setValue(event.target.value);
    i18next.changeLanguage(event.target.value);
  };

  return (
    <Select
      value={value}
      onChange={handleChange}
      // placeholder="Controlled select"
      icon={<CiGlobe />}
      iconColor="#7a7d7d"
      className="SelectLanguage-Select"
    >
      {languages.map(({ code, name, country_code, flag }) => (
        <option key={country_code} value={code} disabled={flag}>
          {t(name)}
        </option>
      ))}
    </Select>
  );
};
