import { Box } from "@chakra-ui/react";
import styled from "styled-components";

export const ViewMoreWrapper = styled.div`
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  @media (max-width: 1300px) {
    /* background-color: lightblue; */
  }
  @media (max-width: 1000px) {
    padding: 2rem;
    /* background-color: lightpink; */
  }
  @media (max-width: 600px) {
    padding: 1.5rem;
    /* background-color: #ffa; */
  }
`;

export const ImgCompareBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 4rem;
  grid-column-gap: 2rem;
  justify-items: center;
  @media (max-width: 1300px) {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-wrap: wrap;
    /* gap: 1rem; */
    grid-row-gap: 0rem;
    grid-column-gap: 0rem;
    > * + * {
      margin-top: 1rem;
    }
    align-items: center;
  }
`;

export const BgImage = styled.div`
  width: 45%;
  height: 300px;
  background: ${(props) => `url(${props.imgSrc})`};
  background-size: cover;
  background-repeat: no-repeat;
  @media (min-width: 2101px) {
    /* align-self: center; */
    width: 70%;
    height: 400px;
  }
  @media (max-width: 2100px) {
    width: 80%;
    height: 450px;
  }
  @media (max-width: 2050px) {
    height: 430px;
  }
  @media (max-width: 2000px) {
    height: 410px;
  }
  @media (max-width: 1950px) {
    height: 390px;
  }
  @media (max-width: 1900px) {
    height: 370px;
  }
  @media (max-width: 1850px) {
    width: 80%;
    height: 350px;
  }
  @media (max-width: 1800px) {
    height: 330px;
  }
  @media (max-width: 1750px) {
    width: 90%;
    height: 380px;
  }
  @media (max-width: 1700px) {
    height: 360px;
  }
  @media (max-width: 1650px) {
    width: 100%;
    height: 400px;
  }
  @media (max-width: 1600px) {
    height: 380px;
  }
  @media (max-width: 1550px) {
    height: 360px;
  }
  @media (max-width: 1500px) {
    height: 340px;
  }
  @media (max-width: 1450px) {
    height: 320px;
  }
  @media (max-width: 1400px) {
    height: 300px;
  }
  @media (max-width: 1350px) {
    height: 280px;
  }
  /**---------------------------------------------------------------------- */
  @media (max-width: 1300px) {
    align-self: center;
    width: 70%;
    height: 400px;
    :nth-child(even) {
      margin-bottom: 40px;
    }
  }
  @media (max-width: 1250px) {
    height: 380px;
  }
  @media (max-width: 1200px) {
    height: 360px;
  }
  @media (max-width: 1150px) {
    height: 340px;
  }
  @media (max-width: 1100px) {
    height: 320px;
  }
  @media (max-width: 1050px) {
    height: 300px;
  }
  /**od tuka nagore.............///////////////////////////////////////////// */
  @media (max-width: 1000px) {
    width: 60%;
    height: 350px;
  }
  @media (max-width: 950px) {
    height: 320px;
  }
  @media (max-width: 900px) {
    width: 70%;
    height: 360px;
  }
  @media (max-width: 850px) {
    height: 340px;
  }
  @media (max-width: 800px) {
    height: 320px;
  }
  @media (max-width: 750px) {
    height: 300px;
  }
  @media (max-width: 700px) {
    width: 80%;
    height: 300px;
  }
  @media (max-width: 650px) {
    height: 280px;
  }
  @media (max-width: 600px) {
    width: 100%;
    height: 320px;
  }
  @media (max-width: 550px) {
    height: 290px;
  }
  @media (max-width: 500px) {
    height: 260px;
  }
  @media (max-width: 450px) {
    height: 230px;
  }
  @media (max-width: 400px) {
    height: 200px;
  }
  @media (max-width: 350px) {
    height: 170px;
  }
  @media (max-width: 300px) {
    height: 140px;
  }
  @media (max-width: 250px) {
    height: 120px;
  }
`;

//------------------------------------------------------------------------------------------
export const StyledHeading = styled.h1`
  font-size: 1.4375em;
  font-weight: 400;
  line-height: 1.2em;
  text-transform: uppercase;
  color: #4d4e4e;
  display: flex;
  align-items: center;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 3rem;
  padding-bottom: 2rem;
  @media (max-width: 1300px) {
  }
  @media (max-width: 1000px) {
    padding: 2rem;
  }
  @media (max-width: 600px) {
    padding: 1.5rem;
  }
`; //className="StyledHeading"
//------------------------------------------------------------------------------------------

export const StyledText = styled(Box)`
  font-size: 0.875em;
  font-weight: 300;
  line-height: 1.7em;
  color: #7a7d7d;
  display: flex;
  justify-content: center;
  padding: 10px;
`;

export const ContentWrapper = styled.div`
  height: calc(100vh - 150px);
  overflow-y: auto;
  @media (max-width: 1300px) {
    height: calc(100vh - 200px);
  }
`;
