import { AspectRatio } from "@chakra-ui/react";
import styled from "styled-components";

export const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 4rem;
  @media (max-width: 1300px) {
    /* background-color: lightblue; */
  }
  @media (max-width: 1000px) {
    padding: 2rem;
    /* background-color: lightpink; */
  }
  @media (max-width: 600px) {
    padding: 1.5rem;
    /* background-color: #ffa; */
  }
`; //className="MainWrapper"

export const BgWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: ${(props) =>
    `linear-gradient(174deg, rgba(0,0,0,0.3785889355742297) 32%, rgba(255,255,255,1) 83%), url(${props.imgSrc})`};
  background-repeat: no-repeat;
  background-size: cover;
  opacity: ${(props) => props.opacity};
`; //className="BgWrapper"

export const ContentWrapper = styled.div`
  height: calc(100vh - 200px);
  overflow-y: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1600px) {
    height: 100vh;
    overflow-y: auto;
  }
`; //className="ContentWrapper"

//------------------------------------------------------------------------------------------
export const StyledHeading = styled.h1`
  font-size: 1.4375em;
  font-weight: 500;
  line-height: 1.2em;
  text-transform: uppercase;
  /* color: #4d4e4e; */
  color: #171717;
  display: flex;
  align-items: center;
  position: relative;
  @media (max-width: 600px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;
//------------------------------------------------------------------------------------------

export const FeelFree = styled.div`
  font-size: 1.4375em;
  font-weight: 400;
  line-height: 1.2em;
  text-transform: uppercase;
  /* color: #4d4e4e; */
  color: #282828;
  display: flex;
  justify-content: center;
  text-align: center;
  @media (max-width: 1300px) {
    font-size: 1.2em;
  }
  @media (max-width: 600px) {
    font-size: 1em;
  }
`;

export const WaysToContact = styled.div`
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 1.3em;
  font-weight: 400;
  line-height: 1.7em;
  /* color: #7a7d7d; */
  color: #3d3d3d;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  text-align: center;

  @media (max-width: 1500px) {
    font-size: 1.2em;
    flex-direction: column;
    /* gap: 10px; */
    > * + * {
      margin-top: 10px;
    }
    margin-top: 25px;
    margin-bottom: 25px;
  }
  @media (max-width: 1300px) {
    font-size: 1.1em;
  }
  @media (max-width: 1000px) {
    font-size: 1em;
  }
  @media (max-width: 600px) {
    font-size: 0.9em;
  }
`;

export const TitleToMapWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 20px;
  @media (max-width: 1000px) {
    margin-top: 30px;
  }
  @media (max-width: 600px) {
    margin-top: 20px;
  }
`;

export const CustomAspectRatio = styled(AspectRatio)`
  width: 80%;
  align-self: center;
  height: 450px;
  @media (max-width: 1400px) {
    height: 400px;
  }
  @media (max-width: 1000px) {
    height: 350px;
  }
  @media (max-width: 600px) {
    height: 300px;
  }
`;
