import { Icon } from "@chakra-ui/react";
import { MdOutlineDarkMode, MdOutlineLightMode } from "react-icons/md";
import { DarkModeWrapper } from "./DarkThemeIndicator.styled";
import { ThemeContext } from "../../../../state/ThemeContext";
import { useContext } from "react";

export const DarkThemeIndicator = () => {
  const { theme, handleSetTheme } = useContext(ThemeContext);

  const toggle = () => {
    if (theme === "dark") {
      handleSetTheme("light");
    } else {
      handleSetTheme("dark");
    }
  };

  return (
    <DarkModeWrapper>
      <Icon
        w="6"
        h="6"
        as={theme === "dark" ? MdOutlineLightMode : MdOutlineDarkMode}
        onClick={toggle}
      />
    </DarkModeWrapper>
  );
};
