import { Image } from "@chakra-ui/react";
import logo from "../../../../../images/Logos/newLogo.png";
import { CustomStackMenu, MenuWrapper } from "../../Sidebar.styled";
import { Socials } from "../Socials";
import { SelectLanguage } from "../SelectLanguage";
import { Menu } from "../Menu";
import { DarkThemeIndicator } from "../../common/DarkThemeIndicator";
import { BottomText } from "../BottomText/BottomText";

export const SidebarContent = ({ onClose, showLogo }) => {
  // console.log(onClose);
  return showLogo === true ? (
    <MenuWrapper className={`SidebarContent-SideBarMenuWrapper-${showLogo}`}>
      <a href="/">
        <Image id="Logo" src={logo} alt="logo" objectFit="contain"></Image>
      </a>
      <CustomStackMenu className="CustomStackMenu">
        <Menu />
      </CustomStackMenu>
      <Socials />
      <SelectLanguage />
      <DarkThemeIndicator />
      <BottomText />
    </MenuWrapper>
  ) : (
    <MenuWrapper className={`SidebarContent-SideBarMenuWrapper-${showLogo}`}>
      <CustomStackMenu>
        <Menu onClose={onClose} />
      </CustomStackMenu>
      <Socials />
      <SelectLanguage />
      <DarkThemeIndicator />
      <BottomText />
    </MenuWrapper>
  );
};
