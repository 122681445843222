import { useTranslation } from "react-i18next";
import {
  BottomTextWrapper,
  ParentBottomTextsWrapper,
  PoweredBy,
} from "../../Sidebar.styled";

export const BottomText = () => {
  const { t } = useTranslation();

  return (
    <ParentBottomTextsWrapper>
      <BottomTextWrapper className="BottomTextWrapper">
        {t("© 2024 All rights reserved. PZU Kreativa.")}
      </BottomTextWrapper>
      <PoweredBy>
        <a href="https://vasilht.netlify.app/">
          Designed & Developed by VasilHT.
        </a>
      </PoweredBy>
    </ParentBottomTextsWrapper>
  );
};
