import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ApplyNowButton = (props) => {
  const { t } = useTranslation();

  return (
    <Link to={props.whereTo}>
      <Button colorScheme="blackAlpha" sx={{ w: "100%", mt: "10px" }}>
        {t("Join our team!")}
      </Button>
    </Link>
  );
};
