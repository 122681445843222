import { ErrorMessage, useField } from "formik";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input as ChakraInput,
  InputGroup,
  InputLeftAddon,
} from "@chakra-ui/react";
import styled from "styled-components";
// import { useMemo } from "react";

const StyledChakraInput = styled(ChakraInput)({
  // backgroundColor: "red!important",
  // width: "400px!important",
});

// const StyledError = styled(FormHelperText)`
//   color: ${(props) => (props.errorMsg ? "red" : "black")}!important;
// `;

export const MyInput = ({
  name,
  label,
  helperText,
  errorMsg,
  children,
  ...props
}) => {
  const [field] = useField(name);

  // const text = useMemo(() => {
  //   return errorMsg ?? helperText;
  // }, [helperText, errorMsg]);

  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <InputGroup>
        <InputLeftAddon bgColor="#fff" children={children} />
        <StyledChakraInput {...props} {...field} />
      </InputGroup>
      <FormHelperText color="red">
        <ErrorMessage name={name} />
      </FormHelperText>
      {/* <StyledError errorMsg={errorMsg}>{text}</StyledError> */}
    </FormControl>
  );
};
