import { Box, Image } from "@chakra-ui/react";
import styled from "styled-components";

export const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  position: relative;
  @media (max-width: 1300px) {
    /* background-color: lightblue; */
  }
  @media (max-width: 1000px) {
    padding: 2rem;
    /* background-color: lightpink; */
  }
  @media (max-width: 600px) {
    padding: 1.5rem;
    /* background-color: #ffa; */
  }
  /* overflow: hidden; */
`; //className="MainWrapper"

export const BgWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: ${(props) =>
    `linear-gradient(174deg, rgba(0,0,0,0.3785889355742297) 12%, rgba(255,255,255,1) 83%), url(${props.imgSrc})`};
  background-repeat: no-repeat;
  background-size: cover;
  opacity: ${(props) => props.opacity};
`; //className="BgWrapper"

export const ContentWrapper = styled.div`
  height: calc(100vh - 100px);
  overflow-y: auto;
  position: relative;
  /* z-index: 1; */
`; //className="ContentWrapper"

//------------------------------------------------------------------------------------------
export const StyledText = styled(Box)`
  width: 100%;
  font-size: 0.875em;
  font-weight: 500;
  line-height: 1.7em;
  /* color: #7a7d7d; */
  color: #3d3d3d;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  @media (max-width: 600px) {
    /* font-size: 1.14285714em; */
    margin-top: 10px;
    p {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
`;

export const StyledHeading = styled.h1`
  font-size: 1.4375em;
  font-weight: 400;
  line-height: 1.2em;
  text-transform: uppercase;
  /* color: #4d4e4e; */
  color: #171717;
  display: flex;
  align-items: center;
  position: relative;
  @media (max-width: 600px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;
//------------------------------------------------------------------------------------------

export const EmployeesWrapper = styled.a`
  width: 100%;
  height: 250px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  /* padding: 20px; */
`;

export const PlaceholderImage = styled(Image)`
  width: 100%;
  height: 100%;
  transition: transform 0.2s; /* Animation */
  cursor: pointer;
  :hover {
    border: 1px solid #9cde47;
    transition: all 0.2s ease-in-out;
    transform: scale(1.1);
  }
`;
