import styled from "styled-components";

const ScrollIndicatorWrapper = styled.div`
  position: absolute;
  bottom: 8%;
  left: 49%;
`;

export const ScrollingIndicator = () => {
  return (
    <ScrollIndicatorWrapper className="ScrollingIndicatorWrapper">
      <div className="icon-scroll"></div>
    </ScrollIndicatorWrapper>
  );
};
