import { MFCTitle } from "../utils/mockData";
import { useNavigate } from "react-router-dom";
import {
  BgImage,
  ContentWrapper,
  ImgCompareBox,
  StyledHeading,
  ViewMoreWrapper,
} from "../components/viewCasesComponent/viewMoreMetalFreeCeramics/ViewMoreMetalFreeCeramics.styled";
import { Box } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import { ImagesMFC } from "../utils/mockData";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { StyledAiOutlineArrowLeft } from "../common/styles/common.styled";

export const ViewMoreMetalFreeCeramics = () => {
  const [t, i18] = useTranslation();
  const navigate = useNavigate();

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  useEffect(() => {
    document.title = t("Kreativa");
  }, [i18.resolvedLanguage, t]);

  return (
    <Box backgroundColor="#F7F7F7" h="100%">
      {isViewerOpen && (
        <ImageViewer
          src={ImagesMFC}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
      <StyledHeading className="StyledHeading">
        <StyledAiOutlineArrowLeft onClick={() => navigate(-1)} />
        {t(MFCTitle.title)}
      </StyledHeading>
      <ContentWrapper>
        <ViewMoreWrapper>
          <ImgCompareBox>
            {MFCTitle.imgArr.map((img, index) => (
              <BgImage
                imgSrc={img}
                onClick={() => openImageViewer(index)}
              ></BgImage>
            ))}
          </ImgCompareBox>
        </ViewMoreWrapper>
      </ContentWrapper>
    </Box>
  );
};
