import { Checkbox } from "@chakra-ui/checkbox";
import {
  FormControl,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/form-control";
import { ErrorMessage, useField } from "formik";

export const MyCheckbox = ({ children, label, name, ...props }) => {
  const [field] = useField(name);

  return (
    <FormControl>
      <FormLabel className="checkbox">
        <div
          className="MyDivWrapper"
          style={{ diplay: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Checkbox
            {...field}
            {...props}
            type="checkbox"
            height="24px"
            width="24px"
          />
          {children}
        </div>
        {label}
      </FormLabel>
      <FormHelperText color="red">
        <ErrorMessage name={name} />
      </FormHelperText>
    </FormControl>
  );
};
