import styled from "styled-components";

export const MainWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 4rem;
  @media (max-width: 1000px) {
    padding: 2rem;
  }
  @media (max-width: 600px) {
    padding: 1.5rem;
  }
`; //className="MainWrapper"

export const HeadingWrapper = styled.div`
  width: 100%;
`;

export const StyledHeading = styled.h1`
  font-size: 1.4375em;
  font-weight: 400;
  line-height: 1.2em;
  text-transform: uppercase;
  /* color: #4d4e4e; */
  color: #3d3d3d;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;
