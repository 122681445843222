import { Image } from "@chakra-ui/react";
import logo from "../../../images/Logos/newLogo.png";
import { AiOutlineMenu } from "react-icons/ai";
import styled from "styled-components";
// import newLogo from "../../../images/Logos/logo2trans.png";

const HeaderWrapper = styled.div`
  display: none;
  @media (max-width: 1000px) {
    height: 84px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    position: fixed;
    top: 0;
    z-index: 999999999;
    width: 100%;
    background-color: white;
  }
  @media (max-width: 600px) {
    height: 78px;
    padding: 1.5rem;
    /* .navigation_logo {
      height: 40px;
    } za so class*/
    #Logo {
      height: 38px;
    }
    /* background-color: #ffa; */
  }
`;

const Header = ({ showSidebarButton = true, onShowSidebar }) => {
  return (
    <HeaderWrapper className="HeaderWrapper">
      <a href="/">
        <Image
          // className="inverted"
          id="Logo"
          sx={{ height: "44px", width: "auto" }}
          src={logo}
          alt="logo"
          objectFit="contain"
        />
      </a>
      {showSidebarButton && (
        <AiOutlineMenu
          id="burgerIcon"
          onClick={onShowSidebar}
          opacity="0.8"
          style={{
            width: "25px",
            height: "35px",
          }}
        />
      )}
    </HeaderWrapper>
  );
};

export default Header;
