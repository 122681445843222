import { Box } from "@chakra-ui/react";
import styled from "styled-components";

export const MainWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 4rem;
  @media (max-width: 1300px) {
    /* background-color: lightblue; */
  }
  @media (max-width: 1000px) {
    padding: 2rem;
    /* background-color: lightpink; */
  }
  @media (max-width: 600px) {
    padding: 1.5rem;
    /* background-color: #ffa; */
  }
`; //className="MainWrapper"

export const HeadingWrapper = styled.div`
  width: 100%;
`;
//------------------------------------------------------------------------------------------
export const StyledText = styled(Box)`
  width: 100%;
  font-size: 0.875em;
  font-weight: 300;
  line-height: 1.7em;
  /* color: #7a7d7d; */
  color: #3d3d3d;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  &.ItalicIntroText {
    width: 100%;
    font-family: "Cormorant Garamond", serif;
    font-style: italic;
    font-weight: 500;
    font-size: 1.25em;
    line-height: 1.5em;
    margin-bottom: 15px;
    @media (max-width: 600px) {
      font-size: 1em;
      line-height: 1.6em;
    }
  }
  p {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  @media (max-width: 600px) {
    /* font-size: 1.14285714em; */
    p {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
`;

export const StyledHeading = styled.h1`
  font-size: 1.4375em;
  font-weight: 400;
  line-height: 1.2em;
  text-transform: uppercase;
  /* color: #4d4e4e; */
  color: #171717;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;
//------------------------------------------------------------------------------------------
export const TitleText = styled.div`
  display: flex;
  flex-direction: column;
  p {
    margin-top: 20px;
    /* margin-bottom: 20px; */
  }
`;

// export const BgImage = styled.div`
//   width: 100%;
//   height: 400px;
//   background: ${(props) => `url(${props.imgSrc})`};
//   background-size: cover;
//   background-repeat: no-repeat;
//   cursor: pointer;
//   @media (max-width: 2000px) {
//     height: 540px;
//   }
//   @media (max-width: 1950px) {
//     height: 520px;
//   }
//   @media (max-width: 1900px) {
//     height: 500px;
//   }
//   @media (max-width: 1850px) {
//     height: 480px;
//   }
//   @media (max-width: 1800px) {
//     height: 460px;
//   }
//   @media (max-width: 1750px) {
//     height: 440px;
//   }
//   @media (max-width: 1700px) {
//     height: 420px;
//   }
//   @media (max-width: 1650px) {
//     height: 400px;
//   }
//   @media (max-width: 1600px) {
//     height: 380px;
//   }
//   @media (max-width: 1550px) {
//     height: 360px;
//   }
//   @media (max-width: 1500px) {
//     height: 340px;
//   }
//   @media (max-width: 1450px) {
//     height: 320px;
//   }
//   @media (max-width: 1400px) {
//     height: 300px;
//   }
//   @media (max-width: 1350px) {
//     height: 280px;
//   }
//   @media (max-width: 1300px) {
//     height: 300px;
//   }
//   @media (max-width: 1250px) {
//     height: 280px;
//   }
//   @media (max-width: 1200px) {
//     height: 260px;
//   }
//   @media (max-width: 1150px) {
//     height: 240px;
//   }
//   @media (max-width: 1100px) {
//     height: 220px;
//   }
//   @media (max-width: 1050px) {
//     height: 200px;
//   }
//   @media (max-width: 1000px) {
//     height: 320px;
//   }
//   @media (max-width: 950px) {
//     height: 300px;
//   }
//   @media (max-width: 900px) {
//     height: 280px;
//   }
//   @media (max-width: 850px) {
//     height: 260px;
//   }
//   @media (max-width: 800px) {
//     height: 240px;
//   }
//   @media (max-width: 750px) {
//     height: 220px;
//   }
//   @media (max-width: 700px) {
//     height: 200px;
//   }
//   @media (max-width: 650px) {
//     height: 340px;
//   }
//   @media (max-width: 600px) {
//     height: 320px;
//   }
//   @media (max-width: 550px) {
//     height: 300px;
//   }
//   @media (max-width: 500px) {
//     height: 280px;
//   }
//   @media (max-width: 450px) {
//     height: 260px;
//   }
//   @media (max-width: 400px) {
//     height: 240px;
//   }
//   @media (max-width: 350px) {
//     height: 200px;
//   }
//   @media (max-width: 300px) {
//     height: 160px;
//   }
//   @media (max-width: 250px) {
//     height: 140px;
//   }
// `; //slikata

// export const ImagesWrapper = styled.div`
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   grid-gap: 2rem;
//   margin-top: 30px;
//   @media (max-width: 650px) {
//     grid-template-columns: 1fr;
//   }
//   /* @media (max-width: 1000px) {
//     grid-template-columns: 1fr;
//   } fali eden media query tuka za poubavo da izgledaat(ne e bitno) */
//   /* width: 100%; */
//   /* margin-bottom: ${(props) => (props.shouldPutMargin ? "30px" : "0px")}; */
//   /* display: flex;
//   flex-direction: column;
//   gap: 2rem;
//   margin-top: 30px;
//   flex: 1; */
//   /* */
//   /* flex-wrap: wrap;
//   justify-content: space-between; */
//   /* */

//   /*tuka go napraviv i so flex i so grid, go ostaviv so grid. Za flex treba BgImage da ima width: 50% */
// `;

export const ImagesWrapper = styled("div")((props) => ({
  display: "grid",
  gridTemplateColumns: props.templateColumns,
  alignItems: "start",
  justifyContent: "start",
  gridGap: "2rem",
  marginTop: "20px",
  "@media (max-width: 1200px)": {
    gridTemplateColumns: "1fr",
  },
  "@media (max-width: 800px)": {
    gridTemplateColumns: "1fr",
  },
}));

export const ContentWrapper = styled.div`
  height: calc(100vh - 250px);
  overflow-y: auto;
  /* @media (max-width: 1000px) {
    height: calc(100vh - 250px);
  } */
`;
