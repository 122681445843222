import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import { PrivacyPolicyData, TitlePrivacyPolicy } from "../utils/mockData";

import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { StyledAiOutlineArrowLeft } from "../common/styles/common.styled";
import {
  ContentWrapper,
  HeadingWrapper,
  MainWrapper,
  StyledHeading,
  StyledText,
  TitleText,
} from "../components/privacyPolicy/PrivacyPolicy.styled";
import { Box, Heading } from "@chakra-ui/react";

export const PrivacyPolicyPage = () => {
  const [t, i18] = useTranslation();

  useEffect(() => {
    document.title = t("Kreativa");
  }, [i18.resolvedLanguage, t]);

  const navigate = useNavigate();

  return (
    <Box backgroundColor="#f7f7f7" height="100%" className="MobileProsthetics">
      <MainWrapper className="MainWrapper">
        <HeadingWrapper>
          <StyledHeading>
            <StyledAiOutlineArrowLeft onClick={() => navigate(-1)} />
            {t(TitlePrivacyPolicy.title)}
          </StyledHeading>
        </HeadingWrapper>
        <ContentWrapper>
          <TitleText>
            {PrivacyPolicyData.map(({ text, title }) => (
              <>
                {title && (
                  <Heading
                    as="h1"
                    fontSize="1.4375em"
                    fontWeight="300"
                    textTransform="uppercase"
                    lineHeight="1.2em"
                    // color="#4d4e4e"
                    color="#282828"
                  >
                    {parse(t(title))}
                  </Heading>
                )}
                {text && <StyledText>{parse(t(text))}</StyledText>}
              </>
            ))}
          </TitleText>
        </ContentWrapper>
      </MainWrapper>
    </Box>
  );
};
