import { Box } from "@chakra-ui/react";
import styled from "styled-components";

export const CasesComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #f7f7f7;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 3rem;
  padding-bottom: 2rem;
  @media (max-width: 1300px) {
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 3rem;
    padding-bottom: 1rem;
    /* background-color: lightblue; */
  }
  @media (max-width: 1000px) {
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    /* background-color: lightpink; */
  }
  @media (max-width: 900px) {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    /* background-color: #ffa; */
  }
`;

export const BgImage = styled.div`
  width: 45%;
  height: 300px;
  background: ${(props) => `url(${props.imgSrc})`};
  background-size: cover;
  background-repeat: no-repeat;
  @media (min-width: 2101px) {
    align-self: center;
    width: 70%;
    height: 400px;
  }
  @media (max-width: 2100px) {
    align-self: center;
    width: 80%;
    height: 450px;
  }
  @media (max-width: 2050px) {
    align-self: center;
    width: 80%;
    height: 430px;
  }
  @media (max-width: 2000px) {
    align-self: center;
    width: 80%;
    height: 410px;
  }
  @media (max-width: 1950px) {
    height: 390px;
  }
  @media (max-width: 1900px) {
    height: 370px;
  }
  @media (max-width: 1850px) {
    width: 80%;
    height: 350px;
  }
  @media (max-width: 1800px) {
    height: 330px;
  }
  @media (max-width: 1750px) {
    width: 90%;
    height: 380px;
  }
  @media (max-width: 1700px) {
    height: 360px;
  }
  @media (max-width: 1650px) {
    width: 100%;
    height: 400px;
  }
  @media (max-width: 1600px) {
    height: 380px;
  }
  @media (max-width: 1550px) {
    height: 360px;
  }
  @media (max-width: 1500px) {
    height: 340px;
  }
  @media (max-width: 1450px) {
    height: 320px;
  }
  @media (max-width: 1400px) {
    height: 300px;
  }
  @media (max-width: 1350px) {
    height: 280px;
  }
  @media (max-width: 1300px) {
    align-self: center;
    width: 70%;
    height: 400px;
  }
  @media (max-width: 1250px) {
    height: 380px;
  }
  @media (max-width: 1200px) {
    height: 360px;
  }
  @media (max-width: 1150px) {
    height: 340px;
  }
  @media (max-width: 1100px) {
    height: 320px;
  }
  @media (max-width: 1050px) {
    height: 300px;
  }
  @media (max-width: 1000px) {
    width: 70%;
    height: 380px;
  }
  @media (max-width: 950px) {
    height: 360px;
  }
  @media (max-width: 900px) {
    width: 80%;
    height: 380px;
  }
  @media (max-width: 850px) {
    height: 360px;
  }
  @media (max-width: 800px) {
    height: 340px;
  }
  @media (max-width: 750px) {
    height: 320px;
  }
  @media (max-width: 700px) {
    height: 290px;
  }
  @media (max-width: 650px) {
    height: 270px;
  }
  @media (max-width: 600px) {
    width: 100%;
    height: 290px;
  }
  @media (max-width: 550px) {
    height: 270px;
  }
  @media (max-width: 500px) {
    height: 250px;
  }
  @media (max-width: 450px) {
    height: 230px;
  }
  @media (max-width: 400px) {
    height: 190px;
  }
  @media (max-width: 350px) {
    height: 160px;
  }
  @media (max-width: 300px) {
    height: 140px;
  }
  @media (max-width: 250px) {
    height: 120px;
  }
`;

export const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* gap: 2rem; */
  > * + * {
    margin-left: 2rem;
  }
  @media (max-width: 1300px) {
    flex-direction: column;
    /* gap: 20px; */
    > * + * {
      margin-left: 0rem;
      margin-top: 20px;
    }
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    /* gap: 20px; */
  }
`; //className="ImagesWrapper"

export const ImgTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
//------------------------------------------------------------------------------------------
export const StyledHeading = styled.h1`
  font-size: 1.4375em;
  font-weight: 400;
  line-height: 1.2em;
  text-transform: uppercase;
  /* color: #4d4e4e; */
  color: #171717;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`; //className="StyledHeading"

export const StyledText = styled(Box)`
  font-size: 0.875em;
  font-weight: 300;
  line-height: 1.7em;
  /* color: #7a7d7d; */
  color: #3d3d3d;
  display: flex;
  justify-content: center;
  padding: 10px;
`;
//------------------------------------------------------------------------------------------
