import { Box } from "@chakra-ui/react";
import styled from "styled-components";

export const CustomContainer = styled.div`
  @media (min-width: 1001px) {
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    height: 100vh;
    scroll-behavior: smooth;
  }
`; //this is for the scroll-snap functionality only above 1000 width;

export const VideoWrapper = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
  position: relative;
  scroll-margin-top: ${(props) => props.offset}px;
  ::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 32%,
      rgba(255, 255, 255, 0.7) 100%
    );
    z-index: 1;
  }
  .ContentIntroTitle,
  .ItalicIntroText {
    color: #d4d4d4;
  }
  @media (min-width: 1001px) {
    scroll-snap-align: start;
  }
  video {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    z-index: 0;
    opacity: ${(props) => props.opacity};
  }
  @media (max-width: 1000px) {
    height: calc(100vh - 84px);
  }
  @media (max-width: 600px) {
    height: calc(100vh - 78px);
  }
`; //isto kako BackgroundWrapper, prilagodeno za Video

export const CarouselWrapper = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
  position: relative;
  scroll-margin-top: ${(props) => props.offset}px;
  .image-slider {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    z-index: 0;
    opacity: ${(props) => props.opacity};
  }
  .slider-image {
    height: 100%;
  }
  ::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 32%,
      rgba(255, 255, 255, 0.7) 100%
    );
    z-index: 1;
  }
  .ContentIntroTitle,
  .ItalicIntroText {
    color: #d4d4d4;
  }
  @media (min-width: 1001px) {
    scroll-snap-align: start;
  }
  @media (max-width: 1000px) {
    height: calc(100vh - 84px);
  }
  @media (max-width: 600px) {
    height: calc(100vh - 78px);
  }
`; //isto kako BackgroundWrapper, prilagodeno za Carousel

export const BackgroundWrapper = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
  position: relative;
  scroll-margin-top: ${(props) => props.offset}px;
  @media (min-width: 1001px) {
    scroll-snap-align: start;
  }
  ::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${(props) =>
      `linear-gradient(174deg, rgba(0,0,0,0.3785889355742297) 32%, rgba(255,255,255,1) 83%), url(${props.imgSrc})`};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: ${(props) => props.opacity};
  }
  @media (max-width: 1000px) {
    height: calc(100vh - 84px);
  }
  @media (max-width: 600px) {
    height: calc(100vh - 78px);
  }
`; //da wrapnuva i da ima pozadina za sekoja strana vo nego;

export const ContentWrapper = styled.div`
  width: 100%;
  /* gap: 50px; smeneto so owl selector pod nego*/
  > * + * {
    margin-top: 50px;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 4rem;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 6rem;
  position: relative;
  z-index: 99;

  @media (max-width: 1000px) {
    padding-top: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    /* padding-bottom: 3rem; */
  }
  @media (max-width: 600px) {
    padding-top: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    &.home {
      padding-bottom: 7.5rem;
    }
    ,
    &.services {
      padding-bottom: 8rem;
    }
    &.cases,
    &.about-us {
      padding-bottom: 6rem;
    }
  }
  @media (max-height: 800px) {
    > * + * {
      margin-top: 35px;
    }
  }
  @media (max-height: 750px) {
    > * + * {
      margin-top: 30px;
    }
  }
  @media (max-height: 700px) {
    > * + * {
      margin-top: 20px;
    }
  }
  @media (max-height: 650px) {
    > * + * {
      margin-top: 15px;
    }
  }
  @media (max-height: 600px) {
    > * + * {
      margin-top: 20px;
    }
    padding-bottom: 3rem !important;
  }
`;

export const MainHeading = styled.h1`
  font-size: 2em;
  /* color: #7a7d7d; */
  /* color: #6b6b6b; */
  color: #3d3d3d;
  font-weight: 400;
  line-height: 1.7em;
  text-align: right;
  .white-text {
    color: white;
  }
  @media (max-width: 600px) {
    font-size: 1.4375em;
  }
`; //className="ContentIntroTitle" "Designing smiles beyond imagination." etc....

//------------------------------------------------------------------------------------------
export const StyledText = styled(Box)`
  &.ItalicIntroText {
    width: 100%;
    font-family: "Cormorant Garamond", serif;
    font-style: italic;
    font-weight: 500;
    font-size: 1.25em;
    line-height: 1.5em;
    @media (max-width: 600px) {
      font-size: 1em;
      line-height: 1.6em;
    }
  }
  text-align: right;
  @media (max-width: 600px) {
    font-size: 1em;
  }
`;
export const StyledHeading = styled.h1`
  font-size: 1.375em;
  font-weight: normal;
  opacity: 0.9;
  color: #282828;
  @media (max-width: 600px) {
    font-size: 1.21428571em;
  }
`; //className="Title"  "SERVICES" i "CASES"
//------------------------------------------------------------------------------------------
