import { extendTheme } from "@chakra-ui/react";

const breakpoints = {
  sm: "321px",
  md: "501px",
  lg: "1001px",
  xl: "1301px",
  "2xl": "1500px",
};

const theme = extendTheme({
  fonts: {
    heading: "Montserrat",
    body: "Montserrat",
  },
  breakpoints,
  // colors: {
  //   brand: {
  //     100: "#f00",
  //     // ...
  //     900: "#1a202c",
  //   },
  // },
});

export default theme;
