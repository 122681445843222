import React, { useEffect, useState } from "react";
import {
  enable as enableDarkMode,
  disable as disableDarkMode,
  setFetchMethod,
} from "darkreader";

export const ThemeContext = React.createContext();

export const ThemeContextProvider = ({ children }) => {
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");

  const handleSetTheme = (newTheme) => {
    localStorage.setItem("theme", newTheme);
    setTheme(newTheme);
  };

  useEffect(() => {
    if (theme === "dark") {
      setFetchMethod(window.fetch);
      enableDarkMode({
        brightness: 100,
        contrast: 100,
        sepia: 0,
        grayscale: 0,
      });
    } else {
      disableDarkMode();
    }
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, handleSetTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
