import { Box } from "@chakra-ui/react";
import styled from "styled-components";

export const MainWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 4rem;
  @media (max-width: 1300px) {
    /* background-color: lightblue; */
  }
  @media (max-width: 1000px) {
    padding: 2rem;
    /* background-color: lightpink; */
  }
  @media (max-width: 600px) {
    padding: 1.5rem;
    /* background-color: #ffa; */
  }
`; //className="MainWrapper"

export const HeadingWrapper = styled.div`
  width: 100%;
`;
//------------------------------------------------------------------------------------------
export const StyledText = styled(Box)`
  width: 100%;
  font-size: 0.875em;
  font-weight: 300;
  line-height: 1.7em;
  /* color: #7a7d7d; */
  color: #3d3d3d;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  &.ItalicIntroText {
    width: 100%;
    font-family: "Cormorant Garamond", serif;
    font-style: italic;
    font-weight: 500;
    font-size: 1.25em;
    line-height: 1.5em;
    margin-bottom: 15px;
    @media (max-width: 600px) {
      font-size: 1em;
      line-height: 1.6em;
    }
  }
  p {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  @media (max-width: 600px) {
    /* font-size: 1.14285714em; */
    p {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
`;

export const StyledHeading = styled.h1`
  font-size: 1.4375em;
  font-weight: 400;
  line-height: 1.2em;
  text-transform: uppercase;
  /* color: #4d4e4e; */
  color: #171717;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;
//------------------------------------------------------------------------------------------
export const TitleText = styled.div`
  display: flex;
  flex-direction: column;
  p {
    margin-top: 20px;
  }
`;

export const ContentWrapper = styled.div`
  height: calc(100vh - 250px);
  overflow-y: auto;
`;
