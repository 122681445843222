import { useTranslation } from "react-i18next";
import { DynamicButton } from "./ReadMoreButton.styled";
import { Link } from "react-router-dom";

export const ReadMoreButton = (props) => {
  const { t } = useTranslation();
  return (
    <Link to={props.whereTo} className="ReadMoreButton-Link">
      <DynamicButton sx={{ props }}>{t(props.text)}</DynamicButton>
    </Link>
  );
};
