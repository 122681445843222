import { useMediaQuery } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import {
  BackgroundWrapper,
  CarouselWrapper,
  ContentWrapper,
  VideoWrapper,
} from "./HomeBgSection.styled";
import ImageSliderLongerFirst from "../../common/components/ImageSliderLongerFirst/ImageSliderLongerFirst";

export const HomeBgSection = ({
  children,
  id,
  imgSrc,
  videoSrc,
  // videoSrc1,
  // videoSrc2,
  sliderSrc,
  imgStyles,
  opacity,
}) => {
  const [isSmallerThan600] = useMediaQuery("(max-width: 600px)");
  const [isSmallerThan1000] = useMediaQuery("(max-width: 1000px)");
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    setOffset(() => {
      let o = 0;
      if (isSmallerThan1000) {
        o = 84;
      }
      if (isSmallerThan600) {
        o = 78;
      }
      return o;
    });
  }, [isSmallerThan600, isSmallerThan1000]);

  return (
    <>
      {imgSrc && (
        <BackgroundWrapper
          className="BackgroundWrapper"
          id={id}
          imgSrc={imgSrc}
          imgStyles={imgStyles}
          opacity={opacity}
          offset={offset}
        >
          <ContentWrapper className={`${id} HomeBgSection-ContentWrapper`}>
            {children}
          </ContentWrapper>
        </BackgroundWrapper>
      )}
      {videoSrc && (
        <VideoWrapper className="VideoWrapper" id={id} offset={offset}>
          <video
            style={(opacity = { opacity })}
            loop
            muted
            autoPlay
            playsInline
          >
            <source src={videoSrc} type="video/mp4" />
            {/* <source src={videoSrc1} type="video/webm" />
            <source src={videoSrc2} type="video/mov" /> */}
          </video>
          <ContentWrapper className={`${id} HomeBgSection-ContentWrapper`}>
            {children}
          </ContentWrapper>
        </VideoWrapper>
      )}
      {sliderSrc && (
        <CarouselWrapper
          className="CarouselWrapper"
          id={id}
          opacity={opacity}
          offset={offset}
        >
          <ImageSliderLongerFirst images={sliderSrc} />
          <ContentWrapper className={`${id} HomeBgSection-ContentWrapper`}>
            {children}
          </ContentWrapper>
        </CarouselWrapper>
      )}
    </>
  );
};

HomeBgSection.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  videoSrc: PropTypes.string.isRequired,
  sliderSrc: PropTypes.string.isRequired,
}; //if anything else than a string is passed in these props, a warning will be shown in the javascript console. For performance reasons, propTypes is checked only in development mode.
