import {
  AllConsultationsPage,
  ConsultationsData,
  ConsultationsPage,
} from "../utils/mockData";
import { useNavigate } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import parse from "html-react-parser";
import {
  MainWrapper,
  HeadingWrapper,
  ContentWrapper,
  StyledText,
  StyledHeading,
} from "../components/services/Consultations/Consultations.styled";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { StyledAiOutlineArrowLeft } from "../common/styles/common.styled";
import ImageSlider from "../common/components/ImageSlider/ImageSlider";

export const Consultations = () => {
  const [t, i18] = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = t("Kreativa");
  }, [i18.resolvedLanguage, t]);

  return (
    <Box backgroundColor="#f7f7f7" height="100%" className="Consultations-Box">
      <MainWrapper className="MainWrapper">
        <HeadingWrapper>
          <StyledHeading>
            <StyledAiOutlineArrowLeft onClick={() => navigate(-1)} />
            {t(ConsultationsData.title)}
          </StyledHeading>
        </HeadingWrapper>
        <ContentWrapper className="customprotectclass">
          {ConsultationsPage.map(({ text }) => (
            <>{text && <StyledText>{parse(t(text))}</StyledText>}</>
          ))}
          <ImageSlider images={AllConsultationsPage} />
        </ContentWrapper>
      </MainWrapper>
    </Box>
  );
};
