import { HomeBgSection } from "../components/home/HomeBgSection";
import { homeSections } from "../utils/mockData";
import parse from "html-react-parser";
import {
  MainHeading,
  StyledText,
  StyledHeading,
  CustomContainer,
} from "../components/home/HomeBgSection.styled";
import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "@chakra-ui/react";
import { ScrollingIndicator } from "../components/home/ScrollingIndicator/ScrollingIndicator";

export const HomePage = () => {
  const [t, i18] = useTranslation();
  useEffect(() => {
    document.title = t("Kreativa");
  }, [i18.resolvedLanguage, t]);

  const [isSmallerThan1000] = useMediaQuery("(max-width: 1000px)");
  const [showScrollingIndicator, setShowScrollingIndicator] = useState(false);

  setTimeout(() => {
    setShowScrollingIndicator(true);
  }, 3000);

  /*HIGHLIGHT the section when using the SCROLL-SNAP functionality*/
  const containerRef = useRef(null);

  const handleScroll = () => {
    const sections = document.querySelectorAll(
      ".BackgroundWrapper, .VideoWrapper, .CarouselWrapper"
    );
    const links = document.querySelectorAll(".link");
    sections.forEach((section, index) => {
      const rect = section.getBoundingClientRect();
      if (rect.top < window.innerHeight && rect.bottom > 0) {
        links.forEach((link) => link.classList.remove("highlight"));
        links[index].classList.add("highlight");
      }
    });
    // console.log("Sections: ", sections);
    // console.log("Links: ", links);
  };
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <CustomContainer ref={containerRef}>
      {homeSections.map(
        ({
          id,
          imgSrc,
          videoSrc,
          // videoSrc1,
          // videoSrc2,
          sliderSrc,
          imgStyles,
          opacity,
          title,
          contentIntroTitle,
          subtitle,
          btnData,
          className,
        }) => (
          <HomeBgSection
            key={id}
            id={id}
            imgSrc={imgSrc}
            videoSrc={videoSrc}
            // videoSrc1={videoSrc1}
            // videoSrc2={videoSrc2}
            sliderSrc={sliderSrc}
            imgStyles={imgStyles}
            opacity={opacity}
            title={title}
            contentIntroTitle={contentIntroTitle}
            subtitle={subtitle}
            btnData={btnData}
            className={className}
          >
            {title && (
              <StyledHeading className="Title" /*"SERVICES" i "CASES"*/>
                {t(title)}
              </StyledHeading>
            )}
            {contentIntroTitle && (
              /*"Designing smiles beyond imagination." etc....*/
              <MainHeading className="ContentIntroTitle">
                {parse(t(contentIntroTitle))}
              </MainHeading>
            )}
            <StyledText className="ItalicIntroText">{t(subtitle)}</StyledText>
            {btnData && btnData}
          </HomeBgSection>
        )
      )}
      {isSmallerThan1000 &&
        (showScrollingIndicator ? <ScrollingIndicator /> : null)}
    </CustomContainer>
  );
};
