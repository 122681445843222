import { CiFacebook, CiInstagram, CiTwitter } from "react-icons/ci";
import { SocialIconsWrapper } from "../../Sidebar.styled";

export const Socials = () => {
  return (
    <SocialIconsWrapper className="Socials-SocialIconsWrapper">
      <a href="https://www.instagram.com/kreativa____/" target="blank">
        <CiInstagram size="30px" color="#7a7d7d" />
      </a>
      <a href="https://www.facebook.com/kreativa.dental.lab" target="blank">
        <CiFacebook size="30px" color="#7a7d7d" />
      </a>
      <a href="/" target="blank">
        <CiTwitter size="30px" color="#7a7d7d" />
      </a>
    </SocialIconsWrapper>
  );
};
