import { Box } from "@chakra-ui/react";
import styled from "styled-components";

export const MainWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  /* height tuka bese 100vh^ */
  padding: 4rem;
  @media (max-width: 1300px) {
    /* background-color: lightblue; */
  }
  @media (max-width: 1000px) {
    padding: 2rem;
    /* background-color: lightpink; */
  }
  @media (max-width: 600px) {
    padding: 1.5rem;
    /* background-color: #ffa; */
  }
`; //className="MainWrapper"

export const HeadingWrapper = styled.div`
  width: 100%;
`;
//------------------------------------------------------------------------------------------
export const StyledText = styled(Box)`
  width: 100%;
  font-size: 0.875em;
  font-weight: 300;
  line-height: 1.7em;
  /* color: #7a7d7d; */
  color: #3d3d3d;
  display: flex;
  justify-content: center;
  flex-direction: column;
  p {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  @media (max-width: 600px) {
    p {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
`;

export const StyledHeading = styled.h1`
  font-size: 1.4375em;
  font-weight: 400;
  line-height: 1.2em;
  text-transform: uppercase;
  /* color: #4d4e4e; */
  color: #171717;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;
//------------------------------------------------------------------------------------------
export const TitleText = styled.div`
  display: flex;
  flex-direction: column;
  p {
    margin-top: 20px;
    /* margin-bottom: 20px; */
  }
`;

// export const BgImage = styled.div`
//   width: 100%;
//   height: 400px;
//   background: ${(props) => `url(${props.imgSrc})`};
//   background-size: cover;
//   background-repeat: no-repeat;
//   cursor: pointer;
//   @media (max-width: 2000px) {
//     height: 540px;
//   }
//   @media (max-width: 1950px) {
//     height: 520px;
//   }
//   @media (max-width: 1900px) {
//     height: 500px;
//   }
//   @media (max-width: 1850px) {
//     height: 480px;
//   }
//   @media (max-width: 1800px) {
//     height: 460px;
//   }
//   @media (max-width: 1750px) {
//     height: 440px;
//   }
//   @media (max-width: 1700px) {
//     height: 420px;
//   }
//   @media (max-width: 1650px) {
//     height: 400px;
//   }
//   @media (max-width: 1600px) {
//     height: 380px;
//   }
//   @media (max-width: 1550px) {
//     height: 360px;
//   }
//   @media (max-width: 1500px) {
//     height: 340px;
//   }
//   @media (max-width: 1450px) {
//     height: 320px;
//   }
//   @media (max-width: 1400px) {
//     height: 300px;
//   }
//   @media (max-width: 1350px) {
//     height: 280px;
//   }
//   @media (max-width: 1300px) {
//     height: 300px;
//   }
//   @media (max-width: 1250px) {
//     height: 280px;
//   }
//   @media (max-width: 1200px) {
//     height: 260px;
//   }
//   @media (max-width: 1150px) {
//     height: 240px;
//   }
//   @media (max-width: 1100px) {
//     height: 220px;
//   }
//   @media (max-width: 1050px) {
//     height: 200px;
//   }
//   @media (max-width: 1000px) {
//     height: 320px;
//   }
//   @media (max-width: 950px) {
//     height: 300px;
//   }
//   @media (max-width: 900px) {
//     height: 280px;
//   }
//   @media (max-width: 850px) {
//     height: 260px;
//   }
//   @media (max-width: 800px) {
//     height: 240px;
//   }
//   @media (max-width: 750px) {
//     height: 220px;
//   }
//   @media (max-width: 700px) {
//     height: 200px;
//   }
//   @media (max-width: 650px) {
//     height: 340px;
//   }
//   @media (max-width: 600px) {
//     height: 320px;
//   }
//   @media (max-width: 550px) {
//     height: 300px;
//   }
//   @media (max-width: 500px) {
//     height: 280px;
//   }
//   @media (max-width: 450px) {
//     height: 260px;
//   }
//   @media (max-width: 400px) {
//     height: 240px;
//   }
//   @media (max-width: 350px) {
//     height: 200px;
//   }
//   @media (max-width: 300px) {
//     height: 160px;
//   }
//   @media (max-width: 250px) {
//     height: 140px;
//   }
// `; //slikata

export const ImagesWrapper = styled("div")((props) => ({
  display: "grid",
  gridTemplateColumns: props.templateColumns,
  alignItems: "start",
  justifyContent: "start",
  gridGap: "2rem",
  // marginTop: "20px",
  "@media (max-width: 1200px)": {
    gridTemplateColumns: "1fr",
  },
  "@media (max-width: 800px)": {
    gridTemplateColumns: "1fr",
  },
}));

export const ContentWrapper = styled.div`
  height: calc(100vh - 200px);
  overflow-y: auto;
  /* background-color: lightgreen; */
`;
