import AppLayout from "./layout/AppLayout";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HomePage } from "./pages/home-page";
import { ContactPage } from "./pages/contact";
import { AboutUsPage } from "./pages/about-us";
import { ViewCasesPage } from "./pages/view-cases";
import { Services } from "./pages/services";
import { ViewMoreMetalCeramics } from "./pages/view-more-metal-ceramics";
import { ViewMoreMetalFreeCeramics } from "./pages/view-more-metal-free-ceramics";
import { MetalCeramics } from "./pages/metal-ceramics";
import { MetalFreeCeramics } from "./pages/metal-free-ceramics";
import { ColorMatching } from "./pages/color-matching";
import { RemovableDentures } from "./pages/mobile-prosthetics";
import ReactGA from "react-ga4";
// import { NewSection } from "./pages/new-section";
import { WaxUp } from "./pages/wax-up";
import { DentalPhotography } from "./pages/photography";
import { Consultations } from "./pages/consultations";
import { DigitalSmileDesign } from "./pages/digital-smile-design";
import { ApplyFormPage } from "./pages/apply-form";
import { Retainers } from "./pages/retainers";
import { PrivacyPolicyPage } from "./pages/privacy-policy";

// import { disableLandscape } from "./scripts/disable-landscape";
// import { useEffect } from "react";

// ReactGA.initialize("G-DZ6J14ECZR");
const TRACKING_ID = "G-DZ6J14ECZR"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const App = () => {
  // useEffect(() => {
  //   disableLandscape();
  // }, []);

  return (
    <Router>
      <AppLayout>
        <Routes>
          <Route exact path="/" element={<HomePage />} />

          <Route path="services" element={<Services />} />
          <Route path="services/metal-ceramics" element={<MetalCeramics />} />
          <Route
            path="services/metal-free-ceramics"
            element={<MetalFreeCeramics />}
          />
          <Route path="services/color-matching" element={<ColorMatching />} />
          <Route
            path="services/removable-dentures"
            element={<RemovableDentures />}
          />
          {/* <Route path="new-route" element={<NewSection />} /> */}
          <Route path="services/wax-up" element={<WaxUp />} />
          <Route path="services/retainers" element={<Retainers />} />
          <Route
            path="services/dental-photography"
            element={<DentalPhotography />}
          />
          <Route path="services/consultations" element={<Consultations />} />
          <Route
            path="services/digital-smile-design"
            element={<DigitalSmileDesign />}
          />

          <Route path="view-cases" element={<ViewCasesPage />} />
          <Route
            path="view-cases/view-more-metal-ceramics"
            element={<ViewMoreMetalCeramics />}
          />
          <Route
            path="view-cases/view-more-metal-free-ceramics"
            element={<ViewMoreMetalFreeCeramics />}
          />

          <Route path="about-us" element={<AboutUsPage />} />
          <Route path="contact" element={<ContactPage />} />

          <Route path="apply-form" element={<ApplyFormPage />} />
          <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
        </Routes>
      </AppLayout>
    </Router>
  );
};

export default App;
