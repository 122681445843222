import { ImageComparisons } from "../utils/mockData";
import { useNavigate } from "react-router-dom";
import {
  BgImage,
  CasesComponentWrapper,
  ImagesWrapper,
  ImgTextWrap,
  StyledHeading,
  StyledText,
} from "../components/viewCasesComponent/CasesComponent.styled";
import { Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { StyledAiOutlineArrowLeft } from "../common/styles/common.styled";

export const ViewCasesPage = () => {
  const [t, i18] = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = t("Kreativa");
  }, [i18.resolvedLanguage, t]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box backgroundColor="#f7f7f7" className="boxWrapper" h="100vh">
      {ImageComparisons.map(({ id, title, imageCompArr, btnData }) => (
        <CasesComponentWrapper key={id} className="CasesComponent">
          <Box>
            <StyledHeading className="StyledHeading">
              <StyledAiOutlineArrowLeft onClick={() => navigate(-1)} />
              {t(title)}
            </StyledHeading>
            <ImagesWrapper className="ImagesWrapper">
              {imageCompArr.map((imgComp, key) => (
                <ImgTextWrap key={key}>
                  <BgImage imgSrc={imgComp.img} />
                  <StyledText>{t(imgComp.text)}</StyledText>
                </ImgTextWrap>
              ))}
            </ImagesWrapper>
          </Box>
          {btnData}
        </CasesComponentWrapper>
      ))}
    </Box>
  );
};
