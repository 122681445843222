import { DrawerContent } from "@chakra-ui/react";
import styled from "styled-components";

export const MenuWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  padding: 2rem;
  padding-top: 3rem;
  padding-bottom: 6rem;
  flex-direction: column;
  justify-content: space-between;
  z-index: 9999999999;
  #Logo {
    height: 50px;
    width: auto;
  }
  @media (max-width: 1300px) {
    #Logo {
      height: 40px;
    }
  }
  @media (max-width: 1000px) {
    padding-bottom: 10rem;
  }
`;

export const MenuBox = styled.div`
  position: fixed;
  left: 0;
  width: 320px;
  height: 100%;
  background-color: white;
  @media (max-width: 1300px) {
    width: 270px;
  }
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const CustomStackMenu = styled.div`
  font-size: 1em;
  display: flex;
  flex-direction: column;
  flex: 2;
  justify-content: center;
  align-items: flex-start;
  /* gap: 20px; */
  > * + * {
    margin-top: 20px;
  }
  padding-top: 6rem;
  text-transform: uppercase;
  @media (max-height: 700px) {
    padding-top: 3rem;
  }
  @media (max-width: 1300px) {
    /* font-size: 0.875em; */
  }
`;

export const ContactButton = styled.button`
  background-color: #9cde47;
  color: white;
  text-transform: uppercase;
  border-radius: 10px;
  padding-top: 3px;
  padding-right: 3px;
  padding-left: 25px;
  padding-right: 25px;
`;

export const CustomDrawerContent = styled(DrawerContent)`
  background: ${(props) => props.bgColorValue}!important;
  height: calc(100vh - 80px);
  @media (max-width: 600px) {
    height: calc(100vh - 60px);
  }
`; //className="DrawerContent"

export const SocialIconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  /* gap: 1rem; */
  > * + * {
    margin-left: 1rem;
  }
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

export const ParentBottomTextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 20px;
  left: 46px;
  > * + * {
    margin-top: 0.5rem;
  }
  @media (max-width: 1300px) {
    left: 24px;
  }
  @media (max-width: 1000px) {
    left: 50px;
  }
`;

export const PoweredBy = styled.div`
  font-size: 0.6875em;
  font-weight: 300;
  line-height: 1.7em;
  :hover {
    color: #9cde47;
  }
`;

export const BottomTextWrapper = styled.div`
  /* font-size: 0.75em; */
  font-size: 0.6875em;
  /* font-size: 0.625em; */
  font-weight: 300;
  line-height: 1.7em;
`;
