import styled from "styled-components";
import {
  AiOutlineArrowLeft,
  AiOutlineMail,
  AiOutlineDesktop,
} from "react-icons/ai";
import { BiPhone } from "react-icons/bi";
import { VscLocation } from "react-icons/vsc";

export const StyledAiOutlineArrowLeft = styled(AiOutlineArrowLeft)({
  marginRight: "15px",
  width: "25px",
  height: "25px",
  cursor: "pointer",
});

export const StyledAiOutlineDesktop = styled(AiOutlineDesktop)({
  fill: "green",
  marginRight: "8px",
  width: "20px",
  height: "20px",
});

export const StyledAiOutlineMail = styled(AiOutlineMail)({
  fill: "green",
  marginRight: "8px",
  width: "20px",
  height: "20px",
});

export const StyledBiPhone = styled(BiPhone)({
  fill: "green",
  marginRight: "8px",
  width: "20px",
  height: "20px",
});

export const StyledVscLocation = styled(VscLocation)({
  fill: "green",
  marginRight: "8px",
  width: "20px",
  height: "20px",
});
